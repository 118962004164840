.inline-form {
  --background: linear-gradient(
    275deg,
    rgba(255, 255, 255, 0.12) 55%,
    rgba(255, 255, 255, 0.14) 110%
  );
  --color: var(--black);
  --color-rgb: var(--black-rgb);
  --submit-color: #f1f1f1;
}
.layout--theme-light .inline-form,
.layout .group--theme-white .inline-form {
  --background: #f5f5f5;
  --color: var(--black);
  --color-rgb: var(--black-rgb);
  --submit-color: #f1f1f1;
}

.countdown-hero .inline-form,
.countdown-footer .inline-form,
.layout .group--theme-white .landing-form .inline-form {
  --background: linear-gradient(
    80deg,
    rgba(42, 40, 45, 0.7) 20%,
    ease-in-out,
    rgba(19, 18, 21, 0.7) 80%
  );
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
.countdown-hero .inline-form,
.countdown-footer .inline-form {
  --submit-color: var(--dark);
}

* + .inline-form {
  margin-top: 44px;
}
@media (--mobile) {
  * + .inline-form {
    margin-top: 24px;
  }
}

.inline-form__wrap {
  position: relative;
}

.offer-discover__card .inline-form__input {
  margin-top: 24px;
}

.inline-form__input {
  --border-radius: 5px;

  position: relative;

  display: flex;
  max-width: 510px;
  height: 54px;
  margin-right: auto;
  margin-left: auto;

  color: var(--color);

  border-radius: var(--border-radius);
  background: var(--background);
}

.group--theme-black .inline-form__input {
  border: 1px solid rgba(255, 255, 255, 0.6);
  background: linear-gradient(
    274deg,
    rgba(255, 255, 255, 0.12) 56.84%,
    rgba(255, 255, 255, 0.14) 113.13%
  );
}

.inline-form--state-success .inline-form__input {
  opacity: 0;
  pointer-events: none;
}

.inline-form__input::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  border: 1px solid transparent;
  border-radius: var(--border-radius);
  background: linear-gradient(
      135deg,
      rgba(227, 104, 100, 0.3),
      ease-in-out,
      rgba(227, 104, 100, 0)
    )
    border-box;

  pointer-events: none;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.layout--theme-light .inline-form__input::after,
.layout .group--theme-white .inline-form__input::after {
  background: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.1),
      ease-in-out,
      rgba(0, 0, 0, 0.05)
    )
    border-box;
}

.inline-form__input__email {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 100px;
  padding-right: 8px;
  padding-left: 24px;

  font-family: var(--font-family-text);
  font-size: 16px;
  color: var(--black);

  border-radius: 5px;
}
.group--theme-black .inline-form__input__email {
  color: var(--white);
}
.inline-form__input__email:focus {
  outline: none;
}
.inline-form__input__email::placeholder {
  font-family: var(--font-family-text);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}
.inline-form__input__email::placeholder,
:--layout-dark .inline-form__input__email::placeholder,
:--group-dark .inline-form__input__email::placeholder {
  font-family: var(--font-family-text);
  color: var(--white);
}
:--layout-light .inline-form__input__email::placeholder,
:--group-light .inline-form__input__email::placeholder {
  font-family: var(--font-family-text);
  color: var(--black);
}

@media (--mobile) {
  .inline-form__input__email {
    padding-left: 12px;
  }
}

.inline-form__input__submit {
  min-width: 215px;
  margin: 8px 8px 8px 0;
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);

  font-family: var(--font-family-text);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  color: var(--white);

  border-radius: 5px;
  background-color: #fb706b;
  transition: 200ms background-color;
}
.inline-form__input__submit:hover:not([disabled]) {
  background-color: #ff9490;
}
.inline-form__input__submit:active {
  background-color: #fb706b;
}
@media (--mobile) {
  .inline-form__input__submit {
    --horizontal-padding: 12px;

    flex-shrink: 0;
    min-width: 0;
  }
}

.inline-form__success {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--font-family-text);

  font-size: 18px;
  color: var(--color);

  text-align: center;
}
.group--theme-black .inline-form__success,
.hero-formation .inline-form__success {
  color: var(--white);
}

.inline-form__error {
  font-family: var(--font-family-text);

  font-size: 15px;
  color: #ff9490;
  text-align: center;
}
* + .inline-form__error {
  margin-top: 12px;
}
